import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Index';
import GlobalStyle from './components/Shared/GlobalStyles';
import Header from './components/Header/Index';
import Medicare from './components/Medicare';
import BlogDetails from './components/BlogDetail';
import GetData from './components/GetData';
import BlogDetailsNeckPain from './components/Home/HomeTrending/HTBlogs/NeckPain';
import BlogDetailsJointPain from './components/Home/HomeTrending/HTBlogs/JoinPain';
import BlogDetailsNeckPainTypes from './components/Home/HomeTrending/HTBlogs/TypesNeckPain';
import BlogDetailsBenefitPeriod from './components/Home/MedicareArrayBlogs/BenefitPeriod';
import BlogDetailsReviewMedicarePlans from './components/Home/MedicareArrayBlogs/ReviewMedicareBlog';
import BlogDetailsRightMedicarePlans from './components/Home/MedicareArrayBlogs/RightMedicarePlan';
import BlogDetailsSubmitClaim from './components/Home/MedicareArrayBlogs/SubmitAClaim';
import BlogDetailsD2 from './components/Home/Diabetes/DiabetesBlogs/ImportantTest';
import BlogDetailsD3 from './components/Home/Diabetes/DiabetesBlogs/BloodFlow';
import BlogDetailsD4 from './components/Home/Diabetes/DiabetesBlogs/DiabetesTea';
import BlogDetailsD5 from './components/Home/Diabetes/DiabetesBlogs/Workingout';
import BlogDetailsD1 from './components/Home/Diabetes/DiabetesBlogs/HealthierFuture';
import HandleCheck from './components/handles-test';
import BlogDetailsCC from './components/Home/ElderyCare/EDBlogs/ComprehensiveCare';
import BlogDetailsED from './components/Home/ElderyCare/EDBlogs/ElderlyDiet';
import BlogDetailsCO from './components/Home/ElderyCare/EDBlogs/OlderB';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms&conditions';
import MedicareAdvantage from './components/MedicareAdvantage';
import MedicareACA from './components/ACA';
function App() {
  return (
   <>
     <Router>
      <GlobalStyle/>
      <Header/>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/maxwell-berlin/medicare" element={<Medicare />} />
          <Route path="/maxwell-berlin/medicare-advantage-plans" element={<MedicareAdvantage />} />
          <Route path="/maxwell-berlin/details" element={<BlogDetails />} />
          <Route path="/get-data" element={<GetData />} />
          <Route path="/blogs/what-causes-neck-pain" element={<BlogDetailsNeckPain />} />
          <Route path="/blogs/treat-back-pain" element={<BlogDetailsJointPain />} />
          <Route path="/blogs/types-of-neck-pain" element={<BlogDetailsNeckPainTypes />} />
          <Route path="medicare/blogs/benefit-period-for-medicare" element={<BlogDetailsBenefitPeriod />} />
          <Route path="medicare/blogs/right-medicare-plans" element={<BlogDetailsRightMedicarePlans />} />
          <Route path="medicare/blogs/review-medicare-plans" element={<BlogDetailsReviewMedicarePlans />} />
          <Route path="medicare/blogs/submit-a-claim" element={<BlogDetailsSubmitClaim />} />

          <Route path="diabetes/blogs/healthier-future" element={<BlogDetailsD1 />} />
          <Route path="diabetes/blogs/important-test" element={<BlogDetailsD2 />} />
          <Route path="diabetes/blogs/blood-flow" element={<BlogDetailsD3 />} />
          <Route path="diabetes/blogs/diabetes-tea" element={<BlogDetailsD4 />} />
          <Route path="diabetes/blogs/working-out" element={<BlogDetailsD5 />} />

          <Route path="eldery-care/blogs/comprehensive-care" element={<BlogDetailsCC />} />
          <Route path="eldery-care/blogs/elder-diet" element={<BlogDetailsED />} />
          <Route path="eldery-care/blogs/aging" element={<BlogDetailsED />} />
          <Route path="eldery-care/blogs/consider-older" element={<BlogDetailsCO />} />
          <Route path="aca" element={<MedicareACA />} />

          <Route path="/handles" element={<HandleCheck />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
    </Router> 
  </>
  );
}

export default App;
